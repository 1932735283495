import React, { useState } from 'react';
import '../stylesout/SolicitudLuminaria.css'; 
import logo from '../images/logo_entel_ilumina.png'; 

const SolicitudExitosa = () => {
    return (
        <div>
            <img src={logo} alt="Logo" className="logo-out" />
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <div className="form-container-out">
                        <h3 className="text-center">¡Tu solicitud ha sido enviada exitosamente!<br /></h3><br />
                        
                            
                                <p align="center" > Recibirás una copia de tu solicitud, en tu correo eléctronico coporativo. </p>
                                
                            
					</div>
                 </div>           
            </div>
        </div>
    );
};

export default SolicitudExitosa;