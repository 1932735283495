import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../stylesout/SolicitudLuminaria.css'; 
import logo from '../images/logo_entel_ilumina.png'; 

const SolicitudLuminaria = () => {
	const navigate = useNavigate();
    const [formData, setFormData] = useState({
        edificio: '',
        piso: '',
        fecha: '',
        fecha2: '',
        hora: '',
        hora2: '',
        correo: '',
        nombre: '',
        fono: '',
        subgerencia: '',
        responsable: '',
        motivo: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
		e.preventDefault();
		const token = localStorage.getItem('token'); 
		const formDataToSend = new FormData(); 

		// Concatenar el correo con el dominio
		const fullEmail = `${formData.correo}@entel.cl`;
		formDataToSend.append('correo', fullEmail);

		// Agregar otros campos al FormData
		Object.entries(formData).forEach(([key, value]) => {
			if (key !== 'correo') { // Evitar agregar el campo de correo que ya se ha concatenado
				formDataToSend.append(key, value);
			}
		});

		try {
			const response = await fetch(`${process.env.REACT_APP_API_REACT_GUARDA_SOLICITUD_LUMINARIA}`, {
				method: 'POST',
				
				body: formDataToSend, 
			});

			if (response.ok) {
				alert('Solicitud guardada con éxito'); 
				navigate(`/solicitud-exitosa`); 
			} else {
				const errorData = await response.json(); 
				alert('Error al generar la solicitud, por favor intenta nuevamente'); 
			}
		} catch (error) {
			console.error('Error en la solicitud:', error); 
			alert('Error al guardar la solicitud, por favor intenta nuevamente'); 
		}
	};

    return (
        <div>
            <img src={logo} alt="Logo" className="logo-out" />
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <div className="form-container-out">
                        <h3 className="text-center">Solicitud de Iluminación Jornada Inhábil<br /></h3><br />
                        <form onSubmit={handleSubmit} id="solicitudForm">
                            <div classNamse="form-group">
                                <p><i className="fas fa-building"></i> Edificio</p>
                                <select className="form-control" id="edificio" name="edificio" value={formData.edificio} onChange={handleChange} required>
                                    <option value="">Seleccionar el edificio</option>
                                    <option value="TITANIUM">TITANIUM</option>
                                    <option value="CORPORATIVO">CORPORATIVO</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <p><i className="fas fa-layer-group"></i> Piso</p>
                                <input type="number" className="form-control" id="piso" name="piso" value={formData.piso} onChange={handleChange} placeholder="Ejemplo: -1, 3" required />
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <p><i className="fas fa-calendar-alt"></i> Fecha de Inicio</p>
                                    <input type="date" className="form-control" id="fecha" name="fecha" value={formData.fecha} onChange={handleChange} required />
                                </div>
                                <div className="form-group col-md-6">
                                    <p><i className="fas fa-calendar-check"></i> Fecha de Término</p>
                                    <input type="date" className="form-control" id="fecha2" name="fecha2" value={formData.fecha2} onChange={handleChange} required />
                                </div>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <p><i className="fas fa-clock"></i> Hora de Inicio</p>
                                    <input type="time" className="form-control" id="hora" name="hora" value={formData.hora} onChange={handleChange} required />
                                </div>
                                 <div className="form-group col-md-6">
                                    <p><i className="fas fa-clock"></i> Hora de Término</p>
                                    <input type="time" className="form-control" id="hora2" name="hora2" value={formData.hora2} onChange={handleChange} required />
                                </div>
                            </div>

                            <div className="form-group">
                                <p><i className="fas fa-envelope"></i> Correo electrónico corporativo</p>
                                <div style={{ display: 'flex' }}>
                                    <input
                                        type="text"
                                        id="correo"
                                        name="correo"
                                        value={formData.correo}
                                        onChange={handleChange}
                                        required
                                        style={{
                                            flex: 1,
                                            borderTopRightRadius: 0,
                                            borderBottomRightRadius: 0
                                        }}
                                    />
                                    <input
                                        type="text"
                                        value="@entel.cl"
                                        readOnly
                                        style={{
                                            width: '110px',
                                            backgroundColor: '#e0e0e0',
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <p><i className="fas fa-user"></i> Nombres y Apellidos</p>
                                <input type="text" className="form-control" id="nombre" name="nombre" value={formData.nombre} onChange={handleChange} placeholder="Ingresa tus nombres y apellidos" required />
                            </div>

                            <div className="form-group">
                                <label htmlFor="fono">
                                    <p><i className="fas fa-phone"></i> Teléfono de Contacto</p>
                                </label>
                                <div style={{ display: 'flex' }}>
                                    <input
                                        type="text"
                                        value="+569"
                                        readOnly
                                        style={{
                                            width: '70px',
                                            backgroundColor: '#e0e0e0',
                                            borderTopRightRadius: 0,
                                            borderBottomRightRadius: 0
                                        }}
                                    />
                                    <input
                                        type="number"
                                        id="fono"
                                        name="fono"
                                        value={formData.fono}
                                        onChange={handleChange}
                                        required
                                        style={{
                                            flex: 1,
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <p><i className="fas fa-sitemap"></i> Gerencia a la que Pertenece</p>
                                <input type="text" className="form-control" id="subgerencia" name="subgerencia" value={formData.subgerencia} onChange={handleChange} placeholder="Ingresa Gerencia/Subgerencia/Área a la que perteneces" required />
                            </div>

                            <div className="form-group">
                                <p><i className="fa-solid fa-person-circle-check"></i> Responsable del área</p>
                                <input type="text" className="form-control" id="responsable" name="responsable" value={formData.responsable} onChange={handleChange} placeholder="Ingresa Gerente/Subgerente/Responsable del área" required />
                            </div>

                            <div className="form-group">
                                <p><i className="fa-solid fa-person-chalkboard"></i> Motivo de la solicitud</p>
                                <input type="text" className="form-control" id="motivo" name="motivo" value={formData.motivo} onChange={handleChange} placeholder="Ingresa motivo de la solicitud" required />
                            </div>

                            <div align="center">
                                <button id="submitBtn" className="btn btn-submit-out btn-block" type="submit">Enviar Solicitud</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SolicitudLuminaria;