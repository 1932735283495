import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; 
import Menu from './Menu';
import '../styles/AdminDashborad.css'; 

const ReportesMantenimientoZn = () => {
    const navigate = useNavigate();
    const [fechaDesde, setFechaDesde] = useState('');
    const [fechaHasta, setFechaHasta] = useState('');
    const [instaSeleccionado, setInstaSeleccionado] = useState('TODOS');
    const [tipoSeleccionado, setTipoSeleccionado] = useState('TODOS');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleGenerateReport = async (e) => {
        e.preventDefault(); // Prevenir el comportamiento por defecto del formulario

        // Validar fechas
        if (!fechaDesde || !fechaHasta) {
            alert('Por favor, ingresa ambas fechas.');
            return;
        }

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('fechaDesde', fechaDesde);
        formData.append('fechaHasta', fechaHasta);
        formData.append('instalacion', instaSeleccionado); // Cambié 'piso' a 'instalacion'
        formData.append('tipoChecklist', tipoSeleccionado);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_REACT_GENERAR_REPORTE_ZN}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'reporte_mantenimiento.pdf'; // Nombre del archivo
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url); // Liberar el objeto URL
            } else {
                alert('Error al generar el reporte. Por favor intenta nuevamente.');
            }
        } catch (error) {
            console.error('Error al generar el reporte API:', error);
            alert('Error al generar el reporte, por favor intenta nuevamente.');
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="reportes-container">
            <Menu userName={localStorage.getItem('userName')} isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
            <main className="content">
                <div className="dashboard-container">
                    <h2>Generar Reporte de Mantenimiento</h2>
                    <form onSubmit={handleGenerateReport}>
                        <div>
                            <label>
                                Fecha Desde:
                                <input
                                    type="date"
                                    value={fechaDesde}
                                    onChange={(e) => setFechaDesde(e.target.value)}
                                    required
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Fecha Hasta:
                                <input
                                    type="date"
                                    value={fechaHasta}
                                    onChange={(e) => setFechaHasta(e.target.value)}
                                    required
                                />
                            </label>
                        </div>
                        <div>
                            <label>
                                Instalación:
                                <select value={instaSeleccionado} onChange={(e) => setInstaSeleccionado(e.target.value)}>
                                    <option value="">Seleccionar Instalación</option>
                                    <option value="ANTOFAGASTA ADMINISTRATIVO">ANTOFAGASTA ADMINISTRATIVO</option>
                                    <option value="ANTOFAGASTA BALMACEDA">ANTOFAGASTA BALMACEDA</option>
                                    <option value="ARICA ADMINISTRATIVO">ARICA ADMINISTRATIVO</option>
                                                                       <option value="CALAMA ADMINISTRATIVO">CALAMA ADMINISTRATIVO</option>
                                    <option value="COPIAPÓ ADMINISTRATIVO">COPIAPÓ ADMINISTRATIVO</option>
                                    <option value="ILLAPEL R/E">ILLAPEL R/E</option>
                                    <option value="IQUIQUE ADMINISTRATIVO">IQUIQUE ADMINISTRATIVO</option>
                                    <option value="VALLENAR R/E">VALLENAR R/E</option>
                                </select>
                            </label>
                        </div>
                        <div>
                            <label>
                                Tipo de Checklist:
                                <select value={tipoSeleccionado} onChange={(e) => setTipoSeleccionado(e.target.value)}>
                                    <option value="">Seleccionar Tipos</option>
                                    <option value="CLIMA">CLIMA</option>
                                    <option value="ESTANQUE">ESTANQUE</option>
                                    <option value="EXTINTOR">EXTINTOR</option>
                                    <option value="GASFITERIA">GASFITERIA</option>
                                    <option value="ILUMINACIÓN">ILUMINACIÓN</option>
                                    <option value="INFRAESTRUCTURA">INFRAESTRUCTURA</option>
                                    <option value="TABLERO">TABLERO</option>
                                </select>
                            </label>
                        </div>
                        <button type="submit" className="btn-generate-report">
                            Generar Reporte
                        </button>
                    </form>
                </div>
            </main>
        </div>
    );
};

export default ReportesMantenimientoZn;