import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard';
import AdminDashboardCorp from './components/AdminDashboardCorp';
import AdminDashboardZn from './components/AdminDashboardZn';
import Clima from './components/Clima';
import DetIncidencia from './components/DetIncidencia';
import DetIncidenciaZn from './components/DetIncidenciaZn';
import AgregaIncidencias from './components/AgregaIncidencias';
import AgregaIncidenciasCorp from './components/AgregaIncidenciasCorp';
import IncidenciasGrupo from './components/IncidenciasGrupo';
import IncidenciasGrupoCorp from './components/IncidenciasGrupoCorp';
import IncidenciasGrupoZn from './components/IncidenciasGrupoZn';
import AgregaHisto from './components/AgregaHisto';
import EquiposTitan from './components/EquiposTitan';
import EquiposGrupo from './components/EquiposGrupo';
import EquiposZonaNorte from './components/EquiposZonaNorte';
import EquiposGrupoZn from './components/EquiposGrupoZn';
import DetEquipo from './components/DetEquipo';
import AgregarMantenimiento from './components/AgregarMantenimiento';
import DetEquipoTitanClima from './components/DetEquipoTitanClima';
import AgregaIncidenciasZn from './components/AgregaIncidenciasZn';
import TitaniumDashboard from './components/TitaniumDashboard';
import CorporativoDashboard from './components/CorporativoDashboard';
import ZnDashboard from './components/ZnDashboard';
import DetEquipoZn from './components/DetEquipoZn';
import AgregarMantenimientoZn from './components/AgregarMantenimientoZn';
import Disponibilidad from './components/Disponibilidad';
import ReporteMantenimientoTitanium from './components/ReporteMantenimientoTitanium';
import SolicitudLuminaria from './components/SolicitudLuminaria';
import SolicitudExitosa from './components/SolicitudExitosa';
import ReporteMantenimientozn from './components/ReporteMantenimientoZn';



const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/admin" element={<AdminDashboard />} />
				<Route path="/titanium" element={<TitaniumDashboard />} />
				<Route path="/corporativo" element={<CorporativoDashboard />} />
				<Route path="/zona-norte" element={<ZnDashboard />} />
				<Route path="/admin-dashboard-corp" element={<AdminDashboardCorp />} />
				<Route path="/admin-dashboard-zn" element={<AdminDashboardZn />} />
				<Route path="/clima" element={<Clima />} />
				<Route path="/detincidencia/:id" element={<DetIncidencia />} />
				<Route path="/detincidenciaZn/:id" element={<DetIncidenciaZn />} />
				<Route path="/agrega-incidencia" element={<AgregaIncidencias />} />
				<Route path="/agrega-incidenciacorp" element={<AgregaIncidenciasCorp />} />
				<Route path="/agrega-incidencia-zn" element={<AgregaIncidenciasZn />} />
				<Route path="/incidenciasgrupo/:tipo" element={<IncidenciasGrupo />} />
				<Route path="/incidenciasgrupocorp/:tipo" element={<IncidenciasGrupoCorp />} />
				<Route path="/incidenciasgrupozn/:tipo" element={<IncidenciasGrupoZn />} />
				<Route path="/agrega-histo/:id" element={<AgregaHisto />} />
				<Route path="/equipos" element={<EquiposTitan />} />
				<Route path="/equipos-zn" element={<EquiposZonaNorte />} />
				<Route path="/equiposgrupo/:tipo" element={<EquiposGrupo />} />
				<Route path="/equiposgrupozn/:tipo" element={<EquiposGrupoZn />} />
				<Route path="/detequipo-titan/:id" element={<DetEquipo />} />
				<Route path="/detequipo-zn/:id" element={<DetEquipoZn />} />
				<Route path="/detequipo-clima/:id" element={<DetEquipoTitanClima />} />
				<Route path="/agrega-mantenimiento/:id" element={<AgregarMantenimiento />} />
				<Route path="/agrega-mantenimientozn/:id" element={<AgregarMantenimientoZn />} />
				<Route path="/disponibilidad" element={<Disponibilidad />} />
				<Route path="/reportes-titanium" element={<ReporteMantenimientoTitanium />} />
				<Route path="/solicitud-iluminacion" element={<SolicitudLuminaria />} />
				<Route path="/solicitud-exitosa" element={<SolicitudExitosa />} />
				<Route path="/reportes-zn" element={<ReporteMantenimientozn />} />
				
                
            </Routes>
        </Router>
    );
};

export default App;